import { Box, Spinner } from 'native-base'
import React from 'react'

function LoadingSpinner({
  accessibilityLabel,
  testId,
}: {
  accessibilityLabel: string
  testId?: string
}) {
  return (
    <Box
      width={'100%'}
      height={'100vh'}
      bgColor={'relay.black'}
      alignContent={'center'}
      justifyContent={'center'}
    >
      <Spinner
        testID={testId}
        accessibilityLabel={accessibilityLabel}
        size={'lg'}
        color={'relay.volt'}
      />
    </Box>
  )
}

export default LoadingSpinner
