import {
  Box,
  Button,
  HStack,
  Input,
  Radio,
  Stack,
  Text,
  VStack,
} from 'native-base'
import React, { useState } from 'react'
import styled from 'styled-components'

import { useOrderPageContext } from '../../OrderPageContext'
import { SafePlace as SafePlaceType } from '../../types'
import fallbackSafePlaceList, {
  NEIGHBOUR_ID,
  safePlaceIcons,
} from './safePlaceList'
import useSafePlace from './useSafePlace'

const safePlceIconSize = 50
const SafePlaceIcon = styled.img`
  width: ${safePlceIconSize}px;
  height: ${safePlceIconSize}px;
`

const checkboxText =
  'By selecting this, you take responsibility for the loss or damage of any item delivered here'
const neighbourMaxCharacterLimit = 40

const SafePlace: React.FC = () => {
  const {
    orderData: { safePlacesList: safePlacesListFromBE },
  } = useOrderPageContext()

  const {
    loading,
    error,
    onSaveSafePlace,
    selectedPlace,
    setSelectedPlace,
    neighbourDoorNumber,
    setNeighbourDoorNumber,
    clearSafePlace,
  } = useSafePlace()

  const [isEditMode, setIsEditMode] = useState<boolean>(!selectedPlace)

  const isNeighbourSelected = Boolean(selectedPlace?.id === NEIGHBOUR_ID)

  // if BE sends empty array, we assume it's intentional and will use it still
  const safePlacesList = safePlacesListFromBE ?? fallbackSafePlaceList
  const showUpdateButton = !isEditMode && !!selectedPlace

  const handleChangeSavePlace = (safePlaceId: string) => {
    const safePlace = safePlacesList.find(
      (place: SafePlaceType) => place.id === safePlaceId
    )
    setSelectedPlace(safePlace ?? null)
    if (safePlace?.id !== NEIGHBOUR_ID) {
      setNeighbourDoorNumber('')
    }
  }

  const handleSaveSafePlace = async () => {
    if (selectedPlace) {
      await onSaveSafePlace()
      setIsEditMode(false)
    }
  }

  return (
    <Stack mb={6}>
      <HStack justifyContent={'space-between'} alignItems={'center'}>
        <Text fontWeight={'bold'} py={2}>
          Your preferred safe place
        </Text>
        {isEditMode && !!selectedPlace && (
          <Button
            isLoading={loading}
            onPress={() => {
              setSelectedPlace(null)
              clearSafePlace()
            }}
            py={2}
            px={4}
            bgColor={'relay.gray'}
            borderRadius={'full'}
            _text={{
              color: 'relay.volt',
            }}
          >
            Clear
          </Button>
        )}
        {showUpdateButton && (
          <Button
            onPress={() => {
              setIsEditMode(true)
            }}
            py={2}
            px={4}
            bgColor={'relay.gray'}
            borderRadius={'full'}
            _text={{
              color: 'relay.volt',
            }}
          >
            Update
          </Button>
        )}
      </HStack>
      <Stack mt={4}>
        {!isEditMode && selectedPlace?.label ? (
          <HStack alignItems={'center'} space={2}>
            {safePlaceIcons[selectedPlace.id] && (
              <SafePlaceIcon
                src={safePlaceIcons[selectedPlace.id]}
                alt={selectedPlace.label}
              />
            )}
            <Text fontSize={'lg'}>{selectedPlace?.label}</Text>
            {isNeighbourSelected && <Text>{neighbourDoorNumber}</Text>}
          </HStack>
        ) : (
          <>
            <Radio.Group
              name={'preferredSafePlace'}
              accessibilityLabel={'Preferred Safe Place'}
              value={selectedPlace?.id ?? ''}
              onChange={(id: string) => handleChangeSavePlace(id)}
            >
              {safePlacesList.map((safePlace: SafePlaceType) => (
                <>
                  <Radio
                    key={safePlace.id}
                    value={safePlace.id}
                    // do not allow changing selection while request is in progress
                    isDisabled={loading}
                    my={2}
                  >
                    {safePlace.label}
                  </Radio>
                  {isNeighbourSelected && safePlace.id === NEIGHBOUR_ID && (
                    <VStack alignItems={'flex-start'} mt={2} mb={5} ml={8}>
                      <Text fontWeight={'light'} fontSize={'sm'}>
                        Door or flat number
                      </Text>
                      <Input
                        mt={1}
                        variant={'outline'}
                        isFullWidth={false}
                        value={neighbourDoorNumber}
                        onChangeText={(text: string) =>
                          setNeighbourDoorNumber(
                            text.slice(0, neighbourMaxCharacterLimit)
                          )
                        }
                        fontSize={16}
                      />
                    </VStack>
                  )}
                </>
              ))}
            </Radio.Group>
            <Box backgroundColor={'relay.gray'} p={3} my={2}>
              <Text>{checkboxText}</Text>
            </Box>
            <Button
              bgColor={'relay.gray'}
              borderRadius={'full'}
              _text={{
                fontWeight: 'extraBold',
                color: 'relay.volt',
              }}
              alignSelf={['stretch', 'stretch', 'flex-start']}
              px={6}
              onPress={handleSaveSafePlace}
              my={4}
              isDisabled={
                !selectedPlace || (isNeighbourSelected && !neighbourDoorNumber)
              }
              isLoading={loading}
            >
              Save safe place
            </Button>
            {!!error && <Text color={'danger.500'}>{error}</Text>}
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default SafePlace
