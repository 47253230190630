import { getBaseUrl } from './apiRoutes'
import camelCaseObjectKeys from './camelCaseObjectKeys'

const POST_HEADERS_BASE = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const camelCaseResponse = <T>(res: Record<string, unknown>): T =>
  camelCaseObjectKeys(res) as T

const fetchWithCamelCasedResponse = async <T>(
  path: string,
  option?: RequestInit
): Promise<T> => {
  const url = new URL(path, getBaseUrl())
  const response = await window.fetch(url, option)

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  const responseJson = await response.json()
  return camelCaseResponse<T>(responseJson)
}

const fetchApi = {
  get: <T>(path: string) => fetchWithCamelCasedResponse<T>(path),
  post: <T>(path: string, data: unknown): Promise<T> =>
    fetchWithCamelCasedResponse(path, {
      method: 'POST',
      headers: POST_HEADERS_BASE,
      body: JSON.stringify(data),
    }),
}

export default fetchApi
