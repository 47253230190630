import {
  DeliveryStatusType,
  OrderData,
  ReturnOrderData,
  SafePlaceType,
} from '../types'

const mockStatus = DeliveryStatusType.ParcelReceived
const mockDeliveryStatusStringMap = {
  [DeliveryStatusType.Delayed]:
    'Sorry we are slightly delayed, we will update you shortly',
  [DeliveryStatusType.Delivered]:
    'Michael delivered the parcel to a safe place',
  [DeliveryStatusType.OutForDelivery]: 'Michael is two stops away',
  [DeliveryStatusType.ParcelReceived]: 'We have received your parcel',
  [DeliveryStatusType.ReattemptingDelivery]: 'Expected Redelivery: Aug 22',
  [DeliveryStatusType.AwaitingCustomerCollection]: 'Ready for Pick Up',
  [DeliveryStatusType.CollectionExpiredReturnToSender]: 'Returning to Sender',
}

export const mockImg =
  'https://cdn.vox-cdn.com/thumbor/rrBRu45EpQ-PEi5pHlN1SxBy8wM=/0x0:1920x1080/1820x1213/filters:focal(807x387:1113x693):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/72009235/captain_pikachu.6.jpg'
export const mockImg2 =
  'https://lh6.googleusercontent.com/DJMSWi5Xvo1ouxp-ABCoV7gvsdtpc0dMjW8Z8qZXOVeFPV9atVf4aRVrghhhF5qHMOxq6b5jOkjzgP9-zTslSI7VZ6A283yvBHf3sQ9ZAe_i246x48ZbK1rd9-Sk5LSdofNKDyDO'
export const mockImg3 =
  'https://cdn.iconscout.com/icon/free/png-256/free-squirtle-pokemon-water-wartortle-cartoon-32212.png'

const mockOrderData: OrderData = {
  shipmentId: '123',
  trackingNumber: 'RELAYCCD686FFF8BAC',
  shipperOrderNumber: 'mock shipper order number',
  destination: {
    name: 'Peter Parker',
    addressLine1: '23A Wayland Ave',
    addressLine2: null,
    addressLine3: null,
    addressLine4: null,
    town: 'London',
    postalCode: 'E8 2HP',
    countryCode: 'GB',
    county: null,
    telephone: '0123456789',
    email: 'api-checker@relaytech.com',
    coordinates: {
      lat: 51.5007325,
      lon: -0.1272003,
    },
  },
  events: [
    {
      status: DeliveryStatusType.OrderCreated,
      statusDisplaySlug: 'Order details received',
      timestamp: '2023-07-08T01:36:59.270Z',
      imageUrl: mockImg,
    },
    {
      status: DeliveryStatusType.OutForDelivery,
      statusDisplaySlug: 'Out for delivery',
      timestamp: '2023-07-09T01:36:59.270Z',
      imageUrl: mockImg,
    },
    {
      status: DeliveryStatusType.Delivered,
      statusDisplaySlug: 'Parcel delivered',
      timestamp: '2023-07-10T01:36:59.270Z',
      imageUrl: mockImg,
      images: [mockImg, mockImg2, mockImg3],
      courierNote: 'Delivered as requested between bin',
    },
  ],
  deliveryStatus: mockStatus,
  statusDisplayString: mockDeliveryStatusStringMap[mockStatus],
  estimatedArrivalWindowStartAt: '2023-07-10T00:36:59.270Z',
  estimatedArrivalWindowEndAt: '2023-07-10T01:36:59.270Z',
  expectedDeliveryTimeDisplayString: '4th July',
  parcelDeliveredAt: '2023-07-10T01:36:59.270Z',
  parcelDeliveredAtDisplayString: 'Wednesday, 2 August at 03:11PM',
  timezone: 'Europe/London',
  statusBadgeText: null,
  courierNote: null,
  orderDetails: [
    {
      brandName: 'New Balance',
      name: '990v3 Mesh and Suede Sneakers',
      quantity: 1,
    },
    {
      brandName: null,
      name: 'Mock Item 2',
      quantity: 2,
    },
  ],
  brandName: 'JD SPORTS',
  shipperLogo: 'https://track.relaytech.co/assets/logos/asos-light.png',
  safePlacePreference: {
    action: 'set',
    safePlaceType: SafePlaceType.EnclosedPorch,
    neighbourDoorNumber: '123',
  },
  pitstopUid: 'mock-uuid',
  pitstopName: 'Costcutter Newington Green Road',
  pitstopOpeningTimes: [
    {
      dayOfWeek: 1,
      opensAt: '0800',
      closesAt: '2000',
    },
    {
      dayOfWeek: 2,
      opensAt: '0900',
      closesAt: '2000',
    },
    {
      dayOfWeek: 3,
      opensAt: '1000',
      closesAt: '2000',
    },
    {
      dayOfWeek: 4,
      opensAt: '1100',
      closesAt: '2000',
    },
    {
      dayOfWeek: 5,
      opensAt: '1200',
      closesAt: '2000',
    },
    {
      dayOfWeek: 6,
      opensAt: '1300',
      closesAt: '2000',
    },
    {
      dayOfWeek: 7,
      opensAt: '1400',
      closesAt: '2000',
    },
  ],
  pitstopAddress: '115 Newington Green Road',
  pitstopPostcode: 'N1 4QY',
  pitstopGeoLat: 51.5510427,
  pitstopGeoLong: 0.0199304,
}

// TODO - update mock data once new endpoint merged
export const mockReturnData: ReturnOrderData = {
  trackingNumber: 'RET31FV343X',
  deliveryStatus: DeliveryStatusType.Delivered,
  expectedDeliveryTimeDisplayString: 'Delivered to retailer',
  statusDisplayString: 'Your parcel has been successfully delivered to ASOS',
  events: [
    {
      status: DeliveryStatusType.AwaitingDropOff,
      statusDisplaySlug: 'Awaiting drop-off',
      timestamp: '2023-07-08T01:36:59.270Z',
      imageUrl: mockImg,
    },
    {
      status: DeliveryStatusType.ReceivedAtPitstop,
      statusDisplaySlug: 'Received by Relay',
      timestamp: '2023-07-09T01:36:59.270Z',
      imageUrl: mockImg,
    },
    {
      status: DeliveryStatusType.InTransit,
      statusDisplaySlug: 'In transit',
      timestamp: '2023-07-10T01:36:59.270Z',
      imageUrl: mockImg,
    },
    {
      status: DeliveryStatusType.Delivered,
      statusDisplaySlug: 'Delivered to retailer',
      timestamp: '2023-07-10T01:45:59.270Z',
      imageUrl: mockImg,
      images: [mockImg, mockImg2, mockImg3],
    },
  ],
}

export default mockOrderData
