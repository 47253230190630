import React from 'react'

import LoadingSpinner from '../../core/LoadingSpinner'
import NoMatch from '../NoMatch'
import { OrderPageContextProvider } from './OrderPageContext'
import ReturnOrder from './ReturnOrder'
import ShipmentOrder from './ShipmentOrder'
import { OrderType } from './types'
import { useGetOrderData } from './useGetShipment'

function OrderPage() {
  const { isLoading, orderData, error, orderType } = useGetOrderData()

  if (isLoading) {
    return (
      <LoadingSpinner
        testId={'AppLoadingSpinner'}
        accessibilityLabel={'Loading the App'}
      />
    )
  }

  if (!orderData || error) {
    return <NoMatch error={error || 'Shipment data not expected'} />
  }

  return (
    <OrderPageContextProvider orderData={orderData}>
      {orderType === OrderType.Return ? (
        <ReturnOrder orderData={orderData} />
      ) : (
        <ShipmentOrder orderData={orderData} />
      )}
    </OrderPageContextProvider>
  )
}

export default OrderPage
