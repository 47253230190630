import { Center, Spinner, Text } from 'native-base'
import React, { PropsWithChildren, ReactNode } from 'react'

import { Coordinate } from '../../pages/Order/types'
import {
  GoogleMap,
  GoogleMapsLoader,
  GoogleMapsLoaderStatus,
  GoogleMapsMarker,
} from './GoogleMaps'
import homePinIcon from './homePinIcon'
import { mapContainerStyles, mapDefaultOptions, mapStyles } from './mapOptions'
import RelayLogo from './RelayLogo'
import ShipperLogo from './ShipperLogo'

const markerIcon = {
  url: homePinIcon,
}

const renderGoogleMaps = ({
  status,
  bounds,
  shipperLogo,
  brandName,
  mapOptionsOverride,
  children,
}: {
  status: GoogleMapsLoaderStatus
  bounds: Coordinate[]
  shipperLogo?: string | null
  brandName?: string | null
  mapOptionsOverride?: google.maps.MapOptions
  children?: ReactNode
}) => {
  const mapOptions: google.maps.MapOptions = {
    ...mapDefaultOptions,
    center: {
      lat: bounds[0].lat,
      lng: bounds[0].lon,
    },
    styles: mapStyles,
    ...mapOptionsOverride,
  }

  switch (status) {
    case GoogleMapsLoaderStatus.LOADING:
      return (
        <Center height={mapContainerStyles?.height}>
          <Spinner
            testID={'MapSpinner'}
            accessibilityLabel={'Loading Google Maps'}
            size={'lg'}
            color={'relay.volt'}
          />
        </Center>
      )
    case GoogleMapsLoaderStatus.FAILURE:
      return (
        <Center
          height={mapContainerStyles?.height}
          borderRadius={mapContainerStyles?.borderRadius}
          bgColor={'relay.slate'}
          padding={6}
        >
          <Text fontSize={'xl'} textAlign={'center'}>
            Something went wrong when loading the map.
          </Text>
        </Center>
      )
    case GoogleMapsLoaderStatus.SUCCESS:
      return (
        <GoogleMap
          styles={mapContainerStyles}
          mapOptions={mapOptions}
          bounds={bounds}
        >
          <RelayLogo />
          <ShipperLogo logo={shipperLogo} name={brandName} />
          <GoogleMapsMarker position={bounds[0]} icon={markerIcon} />
          {children}
        </GoogleMap>
      )
  }
}

type MapProps = {
  /**
   * The bounds for the map view. The map center will be initially defaulted to
   * first coordinate in the array, and then as the map loads, we will fit the
   * map viewport to the bounds. If there is only one coordinate in the array,
   * we don't fit to bound, but simply set the map center and zoom level.
   */
  bounds: Coordinate[]
  shipperLogo?: string | null
  brandName?: string | null
  mapOptionsOverride?: google.maps.MapOptions
}

/**
 * Loads Google Maps Api and then renders a map
 */
function Map({
  bounds,
  shipperLogo,
  brandName,
  mapOptionsOverride,
  children,
}: PropsWithChildren<MapProps>) {
  return (
    <GoogleMapsLoader
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
      render={(status: GoogleMapsLoaderStatus) =>
        renderGoogleMaps({
          status,
          bounds,
          shipperLogo,
          brandName,
          mapOptionsOverride,
          children,
        })
      }
    />
  )
}

export default Map
