import { Box, ChevronRightIcon, HStack, Text, VStack } from 'native-base'
import { Pressable } from 'native-base'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import HomeIcon from '../../core/Icons/HomeIcon'
import Layout from '../../core/Layout'
import Map, { GoogleMapsMarker } from '../../core/Map'
import walkIcon from '../../core/Map/walkIcon'
import { useIsCollection } from '../Collection'
import { Header } from './OrderTracking'
import { getHeaderCopies, getProgressValue } from './OrderTracking'
import Accordion from './OrderTracking/Accordion'
import Feedback from './OrderTracking/Feedback'
import { PhoneNumberPrompt } from './OrderTracking/PhoneNumber'
import {
  COLLECTION_SCHEDULED_STATUES,
  Coordinate,
  DeliveryStatusType,
  OrderData,
} from './types'

const getMapBounds = ({
  destination,
  isCollectionScheduled,
  pitstopGeoLat,
  pitstopGeoLong,
}: {
  destination: Coordinate
  isCollectionScheduled: boolean
  pitstopGeoLat?: number
  pitstopGeoLong?: number
}) => {
  const bounds = [destination]

  if (isCollectionScheduled && pitstopGeoLat && pitstopGeoLong) {
    bounds.push({
      lat: pitstopGeoLat,
      lon: pitstopGeoLong,
    })
  }

  return bounds
}

type ShipmentOrderProps = {
  orderData: OrderData
}

function ShipmentOrder({ orderData }: ShipmentOrderProps) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const {
    events,
    destination,
    deliveryStatus: status,
    statusDisplayString,
    estimatedArrivalWindowEndAt,
    estimatedArrivalWindowStartAt,
    expectedDeliveryTimeDisplayString,
    parcelDeliveredAt,
    parcelDeliveredAtDisplayString,
    timezone,
    brandName,
    statusBadgeText,
    shipperLogo,
    trackingNumber,
    pitstopGeoLat,
    pitstopGeoLong,
  } = orderData

  const { label, title, subtitle, badgeText } = getHeaderCopies({
    events,
    status,
    statusDisplayString,
    estimatedArrivalWindowEndAt,
    estimatedArrivalWindowStartAt,
    parcelDeliveredAt,
    parcelDeliveredAtDisplayString,
    timezone,
    statusBadgeText,
    expectedDeliveryTimeDisplayString,
  })

  const isCollection = useIsCollection()
  const isCollectionRedelivery =
    isCollection && status === DeliveryStatusType.DeliveryFailedWillReattempt
  const isCollectionScheduled = COLLECTION_SCHEDULED_STATUES.includes(status)

  return (
    <>
      <Layout
        renderMap={
          destination
            ? () => (
                <Box flex={['none', 'none', 1]}>
                  <Map
                    bounds={getMapBounds({
                      destination: destination.coordinates,
                      isCollectionScheduled,
                      pitstopGeoLat,
                      pitstopGeoLong,
                    })}
                    shipperLogo={shipperLogo}
                    brandName={brandName}
                  >
                    {isCollectionScheduled &&
                      pitstopGeoLat &&
                      pitstopGeoLong && (
                        <GoogleMapsMarker
                          position={{
                            lat: pitstopGeoLat,
                            lon: pitstopGeoLong,
                          }}
                          icon={{
                            url: walkIcon,
                          }}
                        />
                      )}
                  </Map>
                </Box>
              )
            : undefined
        }
      >
        <div>
          {status && (
            <div>
              <Header
                label={label}
                title={title}
                subtitle={subtitle}
                showProgressBar={!isCollectionRedelivery}
                progressBarValue={getProgressValue(status)}
                badgeText={badgeText}
              />
              <Feedback status={status} />
            </div>
          )}
          <PhoneNumberPrompt />
        </div>
        {isCollectionRedelivery && (
          <>
            <Text fontWeight={'bold'}>Not going to be in?</Text>
            <Pressable
              onPress={() =>
                navigate(
                  `/orders/${trackingNumber}/collection?${searchParams.toString()}`
                )
              }
            >
              <HStack
                p={3}
                bgColor={'relay.darkGray'}
                borderTopLeftRadius={8}
                borderTopRightRadius={8}
                alignItems={'center'}
              >
                <Box ml={2} flexShrink={0} mr={4}>
                  <HomeIcon />
                </Box>
                <VStack mr={3} flex={'1'}>
                  <Text fontWeight={'bold'}>Pick up from parcel shop</Text>
                  <Text color={'relay.volt'} fontSize={'sm'}>
                    See pick up location(s) near you
                  </Text>
                  <Text fontSize={'12px'} color={'danger.500'}>
                    To schedule for pick up, confirm your choice by 11:59pm
                  </Text>
                </VStack>
                <Box flexShrink={0} width={'16px'}>
                  <ChevronRightIcon />
                </Box>
              </HStack>
            </Pressable>
          </>
        )}
        <Accordion />
      </Layout>
    </>
  )
}

export default ShipmentOrder
