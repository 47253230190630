import dayjs from 'dayjs'
import { Accordion as NativeBaseAccordion, Stack, VStack } from 'native-base'
import React from 'react'

import ParcelIcon from '../../core/Icons/ParcelIcon'
import {
  LAYOUT_HEIGHT_WITH_BREAKPOINTS,
  SPACE_WITH_BREAKPOINTS,
} from '../../core/Layout'
import Timeline from '../../core/Timeline'
import NoMatch from '../NoMatch'
import { getProgressValue, Header } from './OrderTracking'
import AccordionItem from './OrderTracking/Accordion/AccordionItem'
import { Event, isReturnDataType, OrderData, ReturnOrderData } from './types'

type ReturnOrderProps = {
  orderData: OrderData
}
function ReturnOrder({ orderData: orderDataProp }: ReturnOrderProps) {
  const orderData = isReturnDataType(orderDataProp)
    ? (orderDataProp as ReturnOrderData)
    : null

  if (!orderData) {
    return <NoMatch error={'Return data is not expected. Please try again.'} />
  }

  const {
    deliveryStatus,
    statusDisplayString,
    expectedDeliveryTimeDisplayString,
    trackingNumber,
    events,
  } = orderData

  return (
    <Stack
      direction={['column']}
      bg={'black'}
      height={LAYOUT_HEIGHT_WITH_BREAKPOINTS}
      padding={[5, 5, 60]}
      space={SPACE_WITH_BREAKPOINTS}
      alignSelf={['unset', 'unset', 'center']}
      width={['100%', '100%', '100%', '75%', '75%', '50%']}
    >
      <VStack space={6} overflowY={'auto'}>
        <Header
          label={trackingNumber}
          title={expectedDeliveryTimeDisplayString ?? ''}
          subtitle={statusDisplayString}
          progressBarValue={getProgressValue(deliveryStatus)}
          badgeText={''}
        />
        <NativeBaseAccordion
          bgColor={'none'}
          borderWidth={0}
          defaultIndex={[0]}
        >
          <AccordionItem
            index={0}
            title={'Return Events'}
            leadingIcon={<ParcelIcon />}
          >
            <Timeline
              items={events.map(
                ({
                  statusDisplaySlug,
                  timestamp,
                  imageUrl,
                  images,
                  courierNote,
                }: Event) => {
                  return {
                    title: statusDisplaySlug,
                    subtitle: dayjs(timestamp).format('MMMM DD, HH:mm'),
                    imgUrl: imageUrl,
                    images,
                    description: courierNote,
                  }
                }
              )}
            />
          </AccordionItem>
        </NativeBaseAccordion>
      </VStack>
    </Stack>
  )
}

export default ReturnOrder
