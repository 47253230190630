import { useGetOrderData } from '../../pages/Order/useGetShipment'

type BackendFeatureFlags = {
  isLoading: boolean
  error: string
  customerPortalSurveyType: string
  customerPortalSafePlaceSelectionEnabled: boolean
  customerPortalShowPhoneNumberPrompt: boolean
  showCustomerCollection: boolean
}

export function useBackendFeatureFlags(): BackendFeatureFlags {
  const { isLoading, orderData, error } = useGetOrderData()
  const vals = orderData?.featureFlagValues ?? {}

  return {
    isLoading: isLoading,
    error: error,
    customerPortalSurveyType:
      typeof vals.customerPortalSurveyTypeBackend === 'string'
        ? vals.customerPortalSurveyTypeBackend
        : 'rate_delivery',
    customerPortalSafePlaceSelectionEnabled:
      !!vals.customerPortalSafePlaceSelectionEnabled,
    customerPortalShowPhoneNumberPrompt:
      !!vals.customerPortalShowPhoneNumberPrompt,
    showCustomerCollection: !!vals.showCustomerCollection,
  }
}
