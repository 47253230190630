/* eslint-disable react/no-children-prop */
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import {
  Button,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from 'native-base'
import React, { useState } from 'react'

import { useBackendFeatureFlags } from '../../../../core/FeatureFlag/useBackendFeatureFlags'
import CircleCheckIcon from '../../../../core/Icons/CircleCheckIcon'
import { relayColors } from '../../../../theme'
import { useOrderPageContext } from '../../OrderPageContext'
import useSavePhoneNumber from './useSavePhoneNumber'

const COUNTRY_CODE = 'GB'

function PhoneNumberInput() {
  const {
    orderData: { destination, contactPhoneNumber },
    updateOrderData,
  } = useOrderPageContext()
  const { customerPortalShowPhoneNumberPrompt } = useBackendFeatureFlags()
  const { loading, error, setError, savePhoneNumber } = useSavePhoneNumber()

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true)

  const handleSavePhoneNumber = () => {
    if (!isValidPhoneNumber(phoneNumber, COUNTRY_CODE)) {
      setIsPhoneNumberValid(false)
      return
    }

    setIsPhoneNumberValid(true)
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, COUNTRY_CODE)

    if (!parsedPhoneNumber?.number || !parsedPhoneNumber.nationalNumber) {
      setError('Phone number is invalid')
      return
    }

    savePhoneNumber(parsedPhoneNumber.number, (contactPhoneNumber?: string) => {
      if (destination) {
        updateOrderData('destination', {
          ...destination,
          contactPhoneNumber,
        })
      }
      setIsEditMode(false)
      setPhoneNumber('')
      setShowSuccessMessage(true)
    })
  }

  if (!customerPortalShowPhoneNumberPrompt) {
    return null
  }

  return (
    <VStack space={3} mt={8} mb={4}>
      <HStack justifyContent={'space-between'}>
        <Text fontWeight={'bold'}>Phone number</Text>
        {!!contactPhoneNumber && (
          <HStack space={2}>
            <CircleCheckIcon
              color={showSuccessMessage ? relayColors.volt : relayColors.white}
            />
            <Text color={showSuccessMessage ? 'relay.volt' : 'relay.white'}>
              Saved
            </Text>
          </HStack>
        )}
      </HStack>
      {isEditMode && (
        <VStack>
          <Text fontSize={'sm'} mb={4}>
            We will only use this phone number in the event we need to contact
            you about your deliveries.
          </Text>
          <FormControl isInvalid={phoneNumber !== '' && !isPhoneNumberValid}>
            <InputGroup variant={'filled'} width={'100%'}>
              <InputLeftAddon px={3} children={'+44'} />
              <Input
                type={'text'}
                size={'2xl'}
                placeholder={'Phone number'}
                value={phoneNumber}
                onChangeText={(text: string) => setPhoneNumber(text)}
                focusOutlineColor={'relay.volt'}
                fontSize={'md'}
                _hover={{
                  borderColor: 'relay.volt',
                }}
                flex={'1'}
                autoFocus
                isDisabled={loading}
              />
            </InputGroup>
            <FormControl.ErrorMessage>
              {error || 'You must enter a phone number'}
            </FormControl.ErrorMessage>
          </FormControl>
        </VStack>
      )}
      <Button
        bgColor={'relay.gray'}
        borderRadius={'full'}
        _text={{
          fontWeight: 'extraBold',
          color: 'relay.volt',
        }}
        alignSelf={['stretch', 'stretch', 'flex-start']}
        px={6}
        mt={2}
        isLoading={loading}
        onPress={() => {
          if (isEditMode) {
            handleSavePhoneNumber()
          } else {
            setIsEditMode(true)
          }
        }}
      >
        {isEditMode ? 'Save phone number' : 'Update phone number'}
      </Button>
    </VStack>
  )
}

export default PhoneNumberInput
