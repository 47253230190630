import { Center, Spinner, Text } from 'native-base'
import React from 'react'

import {
  GoogleMap,
  GoogleMapsLoader,
  GoogleMapsLoaderStatus,
  GoogleMapsMarker,
} from '../../../core/Map/GoogleMaps'
import { ReturnLocation } from '../types'
import { getBounds } from './utils'

const mapDefaultOptions: google.maps.MapOptions = {
  zoom: 15,
}

type Coordinate = {
  lat: number
  lon: number
}

function renderGoogleMaps({
  status,
  mapCenter,
  locations,
}: {
  status: GoogleMapsLoaderStatus
  mapCenter: Coordinate
  locations: ReturnLocation[]
}) {
  const mapOptions: google.maps.MapOptions = {
    ...mapDefaultOptions,
    center: {
      lat: mapCenter.lat,
      lng: mapCenter.lon,
    },
  }

  switch (status) {
    case GoogleMapsLoaderStatus.LOADING:
      return (
        <Center>
          <Spinner
            testID={'MapSpinner'}
            accessibilityLabel={'Loading Google Maps'}
            size={'lg'}
            color={'relay.volt'}
          />
        </Center>
      )
    case GoogleMapsLoaderStatus.FAILURE:
      return (
        <Center bgColor={'relay.slate'} padding={6}>
          <Text fontSize={'xl'} textAlign={'center'}>
            Something went wrong when loading the map. Please try again.
          </Text>
        </Center>
      )
    case GoogleMapsLoaderStatus.SUCCESS:
      return (
        <GoogleMap
          mapOptions={mapOptions}
          styles={{
            height: ['300px', '300px', '100%'],
          }}
          bounds={getBounds(locations)}
        >
          {locations.map(({ code, address }: ReturnLocation, index: number) => (
            <GoogleMapsMarker
              key={code}
              position={{ lat: address.lat, lon: address.lon }}
              label={String(index + 1)}
            />
          ))}
        </GoogleMap>
      )
  }
}

type MapProps = {
  mapCenter: Coordinate
  locations: ReturnLocation[]
}

function Map({ mapCenter, locations }: MapProps) {
  return (
    <GoogleMapsLoader
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
      render={(status: GoogleMapsLoaderStatus) =>
        renderGoogleMaps({ status, mapCenter, locations })
      }
    />
  )
}

export default Map
