import { useSearchParams } from 'react-router-dom'

import { useBackendFeatureFlags } from '../../core/FeatureFlag/useBackendFeatureFlags'

/**
 * A temporary FE hook to gate feature until we have a feature flag
 */
function useIsCollection() {
  const [searchParams] = useSearchParams()
  const { isLoading, showCustomerCollection } = useBackendFeatureFlags()

  return (
    searchParams.get('isCollection') === 'true' ||
    (!isLoading && showCustomerCollection)
  )
}

export default useIsCollection
