import config from '../config.json'

export const getBaseUrl = (): URL | string => {
  if (config.apiDomain) {
    return new URL(config.apiDomain)
  }
  // only for local dev which apiDomain is empty, and `URL` api will
  // throw error
  return ''
}

export const apiRoutes = {
  getShipment: (trackingNumber: string) =>
    `/v0/customer-portal/shipments/${trackingNumber}`,
  getShipmentWithPostcode: (trackingNumber: string, postcode: string) =>
    `/v0/customer-portal/shipments/${trackingNumber}?postcode=${postcode}`,
  noteToCourier: (trackingNumber: string) =>
    `/v0/customer-portal/shipments/${trackingNumber}/note-to-courier`,
  updatePreferredSafePlace: (trackingNumber: string) =>
    `/v0/customer-portal/shipments/${trackingNumber}/safe-place`,
  getReturnLocations: (postcode: string) => `
    v0/pitstops?postcode=${postcode}
  `,
  getReturn: (trackingNumber: string) =>
    `/v0/customer-portal/inbound-shipments/${trackingNumber}`,
  updatePhoneNumber: (trackingNumber: string) =>
    `/v0/customer-portal/shipments/${trackingNumber}/contact-phone`,
  confirmCollection: (trackingNumber: string) =>
    `/v0/customer-portal/shipments/${trackingNumber}/customer-request-collect`,
}
