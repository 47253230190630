import { Accordion, HStack, Text, VStack } from 'native-base'
import React, { ReactNode } from 'react'

type AccordionItemProps = {
  /** index is needed for accordion to work properly */
  index: number
  title: string
  subtitle?: string
  leadingIcon?: ReactNode
  children: ReactNode
}

/**
 * Use accordion components provided from NativeBase to build out styles.
 * Must be used within <Accordion>.
 */
function AccordionItem({
  index,
  title,
  subtitle,
  leadingIcon,
  children,
}: AccordionItemProps) {
  const isFirst = index === 0
  return (
    <Accordion.Item
      index={index}
      bgColor={'relay.darkGray'}
      marginTop={isFirst ? 0 : 2}
    >
      <Accordion.Summary
        _hover={{
          bgColor: 'relay.darkGray',
        }}
        _expanded={{
          bgColor: 'relay.darkGray',
          borderBottomWidth: 1,
        }}
        borderTopColor={'transparent'}
        marginLeft={2}
      >
        <HStack justifyContent={'flex-start'} alignItems={'center'} space={4}>
          {leadingIcon}
          <VStack>
            <Text fontWeight={'bold'}>{title}</Text>
            {subtitle && (
              <Text fontSize={'sm'} color={'relay.volt'}>
                {subtitle}
              </Text>
            )}
          </VStack>
        </HStack>
        <Accordion.Icon />
      </Accordion.Summary>
      <Accordion.Details marginLeft={2} py={4}>
        {children}
      </Accordion.Details>
    </Accordion.Item>
  )
}

export default AccordionItem
