import backdoor from '../../../../images/backdoor.png'
import enclosedPorch from '../../../../images/enclosedPorch.png'
import garage from '../../../../images/garage.png'
import mailArea from '../../../../images/mailArea.png'
import neighbour from '../../../../images/neighbour.png'
import outsideDoorInBuilding from '../../../../images/outsideDoorInBuilding.png'
import parcelBox from '../../../../images/parcelBox.png'
import receptionist from '../../../../images/receptionist.png'
import shed from '../../../../images/shed.png'
import { SafePlace, SafePlacePreference, SafePlaceType } from '../../types'

export const NEIGHBOUR_ID = SafePlaceType.Neighbour

const safePlaceList: SafePlace[] = [
  {
    id: SafePlaceType.Receptionist,
    label: 'Receptionist / concierge',
  },
  {
    id: SafePlaceType.MailArea,
    label: 'Secure lobby or mail area',
  },
  {
    id: SafePlaceType.Neighbour,
    label: 'Neighbour',
  },
  {
    id: SafePlaceType.OutsideDoorInBuilding,
    label: 'Outside Door Inside Building',
  },
  {
    id: SafePlaceType.EnclosedPorch,
    label: 'Enclosed porch',
  },
  {
    id: SafePlaceType.ParcelBox,
    label: 'Parcel box',
  },
  {
    id: SafePlaceType.Shed,
    label: 'Shed',
  },
  {
    id: SafePlaceType.Backdoor,
    label: 'Back or side door',
  },
  {
    id: SafePlaceType.Garage,
    label: 'Garage',
  },
]

export const getSafePlace = (
  safePlacePreference?: SafePlacePreference
): SafePlace | null =>
  safePlaceList.find(
    (safePlace) => safePlace.id === safePlacePreference?.safePlaceType
  ) ?? null

/**
 * Map from the safe place id to the icon
 */
export const safePlaceIcons: Record<SafePlaceType, string> = {
  [SafePlaceType.Receptionist]: receptionist,
  [SafePlaceType.MailArea]: mailArea,
  [SafePlaceType.Neighbour]: neighbour,
  [SafePlaceType.OutsideDoorInBuilding]: outsideDoorInBuilding,
  [SafePlaceType.EnclosedPorch]: enclosedPorch,
  [SafePlaceType.ParcelBox]: parcelBox,
  [SafePlaceType.Shed]: shed,
  [SafePlaceType.Backdoor]: backdoor,
  [SafePlaceType.Garage]: garage,
}

export default safePlaceList
